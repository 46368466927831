/* eslint-disable no-sequences */

import {baseApi} from "../baseApi";

const publicInstructorApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    followInstructor: build.mutation({
      query: (id) => ({
        url: `/instructor/follow/${id}`,
        method: "POST",
        
      }),
      invalidatesTags: ["instructor"],
    }),
    unFollowInstructor: build.mutation({
      query: (id) => ({
        url: `/instructor/unfollow/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["instructor"],
    }),
    publicInstructorGetBySlug: build.query({
      query: (slug) => ({
        url: `/instructor/${slug}/public`,
        method: "GET",
      }),
      providesTags: ["instructor"],
    }),
    getReport: build.query({
      query: () => ({
        url: `/report/public`,
        method: "GET",
      }),
 
    }),
    getAllFollowers: build.query({
      query: () => ({
        url: '/instructor/followers/public',
        method: "GET",
      }),
      providesTags: ["instructor"]
 
    }),
  }),
});

export const {
  usePublicInstructorGetBySlugQuery,
  useFollowInstructorMutation,
  useUnFollowInstructorMutation,
  useGetReportQuery,
  useGetAllFollowersQuery
} = publicInstructorApi;
