import Link from "next/link";
import MainDemoBanner from "./MainDemoBanner";
import Card from "../Cards/Card";
import Counter from "../Counters/Counter";

import BlogGridTop from "../Blogs/Blog-Sections/BlogGrid-Top";

import CategoryTwo from "../Category/CategoryTwo";
import {
  usePublicCategoryGetQuery,
  usePublicCourseGetQuery,
} from "@/redux/api/publicApi/course";

import Loader from "../Loader/Loader";
import About from "../Abouts/About";

import TeamFive from "../Team/TeamFive";
import {useGetAllBlogQuery} from "@/redux/api/blog/blog";
import { convertToBengaliNumerals } from "@/Hooks/convertBangaliDIgit";
import EventCarouse from "../Events/EventCarouse";

const MainDemo = () => {
  const {data, isLoading} = usePublicCourseGetQuery();
  const {data: courseCategory} = usePublicCategoryGetQuery();
  const {data: blogdata} = useGetAllBlogQuery(undefined);


  return (
    <>
      <main className="rbt-main-wrapper">
        <div className="rbt-banner-area rbt-banner-1">
          <MainDemoBanner />
        </div>

        <div className="rbt-categories-area bg-color-white rbt-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="subtitle bg-primary-opacity">
                    আমাদের কোর্সসমুহ
                  </span>
                  <h2 className="title">
                    যেকোনো বিষয়ে যেকোনো কিছু শিখতে চলে যাও <br />
                    তোমার পছন্দের সেকশনে
                  </h2>
                </div>
              </div>
            </div>
            <div className="row g-5 mt--20">
              <CategoryTwo />
            </div>
          </div>
        </div>
        {courseCategory?.data?.map((course, i) => {
          return (
            <>
              {course?.Courses?.length > 0 && (
                <div key={i} className=" bg-color-extra2 ptb--35">
                  <div className="container">
                    <div className="row mb--40">
                      <div className="col-lg-12">
                        <div className="section-title text-center">
                          <span className="subtitle bg-secondary-opacity">
                            {course?.name}
                          </span>
                          <p
                            className="title"
                            dangerouslySetInnerHTML={{
                              __html: course?.description,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <div className="row row--15">
                        <Card
                          coruseDetails={course?.Courses}
                      
                          col="col-lg-4 col-md-6 col-sm-6 col-12"
                          mt="mt--30"
                          start={0}
                          end={3}
                          isDesc={true}
                          isUser={true}
                        />
                      </div>
                    )}
                    {course?.Courses?.length > 3 && (
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="load-more-btn mt--60 text-center">
                            <Link
                              className="rbt-btn btn-gradient btn-lg hover-icon-reverse"
                              href={`/courses/${course?.slug}`}>
                              <span className="icon-reverse-wrapper">
                                <span className="btn-text">
                                আরো দেখুন ({convertToBengaliNumerals(course?.Courses?.length)})
                                </span>
                                <span className="btn-icon">
                                  <i className="feather-arrow-right"></i>
                                </span>
                                <span className="btn-icon">
                                  <i className="feather-arrow-right"></i>
                                </span>
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          );
        })}

        <div className="rbt-about-area bg-color-white rbt-section-gapTop pb_md--80 pb_sm--80 about-style-1">
          <div className="container">
            {/* <AboutFive /> */}
            <About />
          </div>
        </div>

        {/* <div className="rbt-callto-action-area mt_dec--half">
          <CallToAction />
        </div> */}

        <div className="rbt-counterup-area bg-color-extra2 rbt-section-gapBottom default-callto-action-overlap">
          <div className="container">
            <Counter isDesc={false} />
          </div>
        </div>

        {/* <div className="rbt-testimonial-area bg-color-white rbt-section-gap overflow-hidden">
          <div className="wrapper">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center mb--10">
                    <span className="subtitle bg-primary-opacity">
                      সবার জন্য সেরা সলিউশন
                    </span>
                    <h2 className="title">আমাদের সফলতার গল্প</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TestimonialSeven />
        </div> */}

        <div className="rbt-event-area rbt-section-gap bg-gradient-3">
          <div className="container">
            <div className="row mb--55">
              <div className="section-title text-center">
                <span className="subtitle bg-white-opacity">
                আপনার অংশগ্রহণে রঙিন হোক 
                </span>
                <h2 className="title color-white">আমাদের ইভেন্ট</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <EventCarouse />
              </div>
            </div>
          </div>
        </div>

        <div className="rbt-team-area bg-color-white rbt-section-gap">
          <div className="container">
            <div className="row mb--60">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="subtitle bg-primary-opacity">
                    আমাদের শিক্ষক
                  </span>
                  <h2 className="title">
                    সেরা শিক্ষকদের পরিচর্যায় দেশের যেকোন প্রান্ত থেকে <br />{" "}
                    অব্যাহত থাকুক পড়াশুনার অগ্রযাত্রা
                  </h2>
                </div>
              </div>
            </div>
            <TeamFive />
          </div>
        </div>

        <div className="rbt-rbt-blog-area rbt-section-gap bg-color-extra2">
          <div className="container">
            <div className="row g-5 align-items-center mb--30">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="section-title">
                  <span className="subtitle bg-pink-opacity">ব্লগ</span>
                  {/* <h2 className="title">Post Popular Post.</h2> */}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="hellos"></div>
                <div className="read-more-btn text-start text-md-end">
                  <Link
                    className="rbt-btn btn-gradient hover-icon-reverse"
                    href="/blogs">
                    <div className="icon-reverse-wrapper">
                      <span className="btn-text">আরো দেখুন</span>
                      <span className="btn-icon">
                        <i className="feather-arrow-right"></i>
                      </span>
                      <span className="btn-icon">
                        <i className="feather-arrow-right"></i>
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <BlogGridTop blogs={blogdata?.data} />
          </div>
        </div>

        {/* <div className="rbt-newsletter-area newsletter-style-2 bg-color-primary rbt-section-gap">
          <NewsletterTwo />
        </div> */}
      </main>
    </>
  );
};

export default MainDemo;
