import {convertToBengaliNumerals} from "@/Hooks/convertBangaliDIgit";
import {
  useAddWishlistMutation,
  useGetWishlistQuery,
  useRemoveWishlistMutation,
} from "@/redux/api/user/user";
import Image from "next/image";
import Link from "next/link";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
const Card = ({start, end, col, mt, isDesc, isUser, coruseDetails}) => {
  const [skip, setSkip] = useState(true);
  const {data: wishListData} = useGetWishlistQuery(undefined, {skip});
  const {user} = useSelector((state) => state?.auth);
  const [addWishlist] = useAddWishlistMutation();
  const [removeWishlist] = useRemoveWishlistMutation();
  useEffect(() => {
    const tokenString = localStorage.getItem("persist:auth");
    const tokenObj = JSON.parse(tokenString)?.token;

    if (
      tokenObj &&
      tokenObj !== "null" &&
      tokenObj !== "" &&
      user?.type === "student"
    ) {
      setSkip(false);
    } else {
      setSkip(true);
    }
  }, [skip, user?.token, user?.type
  ]);
  const addToWishlist = async (product) => {
    const data = {
      coursesId: product?.id,
      quizId: null,
    };
    const res = await addWishlist(data);
    if (res?.data?.success === true) {
      toast.success(res?.data?.message);
    }
  };
  const removeToWishlist = async (product) => {
    // const res = await removeWishlist(product?.id);
    // if (res?.data?.success === true) {
    //   toast.success(res?.data?.message);
    // }
  };

  const getPriceAfterDiscount = (data) => {
 
    let discountAmount =
      (data?.CurrentCourseBatch?.CourseBatch?.discountPercentage / 100) *
      data?.CurrentCourseBatch?.CourseBatch?.price;

    return convertToBengaliNumerals(
      (data?.CurrentCourseBatch?.CourseBatch?.price - discountAmount)
    );
  };

  const isInWishList = (product) => {
    return wishListData?.data?.find(
      (ele, index) => ele.coursesId === product?.id
    );
  };

  return (
    <>
      {coruseDetails &&
        coruseDetails?.slice(start, end)?.map((data, index) => (
          <div
            className={`${col} ${mt}`}
            data-sal-delay="150"
            data-sal="slide-up"
            data-sal-duration="800"
            key={index}>
            <div className="rbt-card variation-01 rbt-hover">
              <div className="rbt-card-img">
                <Link href={`/course-details/${data?.slug}`}>
                  <Image
                    src={`${process.env.NEXT_PUBLIC_FILE_BASE}${data?.thumbnail}`}
                    width={355}
                    height={244}
                    alt="Card image"
                  />
                  {data?.CurrentCourseBatch?.CourseBatch?.discountPercentage >
                    0 && (
                    <div className="rbt-badge-3 bg-white">
                      <span>
                        {
                          data?.CurrentCourseBatch?.CourseBatch
                            ?.discountPercentage
                        }
                        %
                      </span>
                      <span>ছাড়</span>
                    </div>
                  )}
                </Link>
              </div>
              <div className="rbt-card-body">
                <div className="rbt-card-top">
                  <div className="rbt-review">
                    <div className="rating">
                      {/* <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i> */}
                    </div>
                    <span className="rating-count d-flex gap-2 justify-content-center">
                      <div>
                        <i className="feather-users"></i>
                      </div>

                      <div>
                        {" "}
                        {convertToBengaliNumerals(
                          data?.CurrentCourseBatch?.CourseBatch
                            ?.availableSeatsCount
                        )}{" "}
                        সিট বাকি
                      </div>
                    </span>
                  </div>
                  {isInWishList(data) ? (
                    <div
                      className="rbt-bookmark-btn"
                      onClick={() => removeToWishlist(data)}>
                      <button
                        type="button"
                        className="rbt-round-btn"
                        title="Wishlist">
                        <i
                          style={{color: "blue"}}
                          className="feather-bookmark"></i>
                      </button>
                    </div>
                  ) : (
                    <div
                      className="rbt-bookmark-btn"
                      onClick={() => addToWishlist(data)}>
                      <button
                        type="button"
                        className="rbt-round-btn"
                        title="Wishlist">
                        <i className="feather-bookmark"></i>
                      </button>
                    </div>
                  )}
                </div>

                <h4 className="rbt-card-title">
                  <Link href={`/course-details/${data?.slug}`}>
                    {data?.title}
                  </Link>
                </h4>

                <ul className="rbt-meta">
                  {/* <li> */}
                  {/* <i className="feather-book"></i> */}
                  {/* {data?.CurrentCourseBatch?.CourseBatch.CourseMilestone.flatMap(
                        (milestone) =>
                          milestone.CourseModule.flatMap((module) =>
                            module.CourseLession.map((lesson) => ({
                              title: lesson.title,
                            }))
                          )
                      )} */}
                  {/* Lessons */}
                  {/* </li> */}
                  {/* <li>
                    <i className="feather-users"></i>
                    {
                      data?.CurrentCourseBatch?.CourseBatch?.availableSeatsCount
                    }{" "}
                     সিট বাকি
                  </li> */}
                </ul>
                {isDesc ? <p className="rbt-card-text">{data.desc}</p> : ""}
                {isUser ? (
                  <div className="rbt-author-meta mb--10">
                    <div className="rbt-avater">
                      <Link href={`/profile/${data?.id}`}>
                        <Image
                          src={`${process.env.NEXT_PUBLIC_FILE_BASE}${data?.Instructor?.User?.profileImageUrl}`}
                          width={33}
                          height={33}
                          alt={data?.Instructor?.User?.name}
                        />
                      </Link>
                    </div>
                    <div className="rbt-author-info">
                      By{" "}
                      <Link
                        href={`/instructor-profile/${data?.Instructor?.slug}`}>
                        {data?.Instructor?.User?.name}
                      </Link>{" "}
                      {/* In <Link href="#">{data?.userCategory}</Link> */}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="rbt-card-bottom">
                  <div className="rbt-price">
                    <span className="current-price">
                      {data?.type === "Free"
                        ? "ফ্রি"
                        : `৳ ${getPriceAfterDiscount(data)}`}
                    </span>
                    {data?.CurrentCourseBatch?.CourseBatch?.discountPercentage >
                      0 && (
                      <span className="off-price">
                        ৳{" "}
                        {convertToBengaliNumerals(
                          data?.CurrentCourseBatch?.CourseBatch?.price
                        )}
                      </span>
                    )}
                  </div>

                  <Link
                    className="rbt-btn-link left-icon"
                    href={`/course-details/${data?.slug}`}>
                    <i className="feather-shopping-cart"></i> কোসটি কিনুন
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default Card;
