/* eslint-disable no-sequences */

import {baseApi} from "../baseApi";

const blogApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createBlog: builder.mutation({
      query: (blogData) => ({
        url: "/blog/new",
        method: "POST",
        data: blogData,
      }),
      invalidatesTags: ["blog"],
    }),
    getAllBlog: builder.query({
      query: () => ({
        url: "/blog/public",
        method: "GET",
      }),
      providesTags: ["blog"],
    }),
    getAllPendingBlogs: builder.query({
      query: () => ({
        url: "/blog/pending",
        method: "GET",
      }),
      providesTags: ["blog"],
    }),
    getBlogById: builder.query({
      query: (id) => ({
        url: `/blog/${id}`,
        method: "GET",
      }),
      providesTags: ["blog"],
    }),
    getBlogBySlug: builder.query({
      query: (slug) => ({
        url: `/blog/${slug}/public`,
        method: "GET",
      }),
      providesTags: ["blog"],
    }),
    createBlogCategory: builder.mutation({
      query: (blogCategoryData) => ({
        url: "/blog-category/new",
        method: "POST",
        data: blogCategoryData,
      }),
      invalidatesTags: ["blog-category"],
    }),
    approvedBlogById: builder.mutation({
      query: (data) => ({
        url: `/blog/approved/${data?.id}`,
        method: "PUT",
        data: data?.title,
      }),
      invalidatesTags: ["blog"],
    }),
    deleteBlogByID: builder.mutation({
      query: (id) => ({
        url: `/blog/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["blog"],
    }),
    getAllBlogCategories: builder.query({
      query: () => ({
        url: "/blog-category/public",
        method: "GET",
      }),
      providesTags: ["blog-category"],
    }),
  }),
});

export const {
  useCreateBlogMutation,
  useCreateBlogCategoryMutation,
  useGetAllBlogQuery,
  useGetBlogByIdQuery,
  useGetAllBlogCategoriesQuery,
  useGetAllPendingBlogsQuery,
  useApprovedBlogByIdMutation,
  useDeleteBlogByIDMutation,
  useGetBlogBySlugQuery,
} = blogApi;
