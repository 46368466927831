import React from "react";
import Image from "next/image";


import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { useGetAllInstructorQuery } from "@/redux/api/instructor/instructor";
const TeamFive = () => {
  const { data } = useGetAllInstructorQuery();
  
  return (
    <>
      <div className="container">
        {/* <TeamHead title="Team (Card Box)." desc="Card Box Style." /> */}
        <div className="row row--15">
          <div className="col-lg-12">
            <Swiper
              className="swiper team-slide-activation rbt-arrow-between rbt-dot-bottom-center"
              modules={[Navigation, Pagination,Autoplay]}
              loop={true}
              spaceBetween={30}
              slidesPerView={1}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              pagination={{
                el: ".rbt-swiper-pagination",
                clickable: true,
              }}
              navigation={{
                nextEl: ".rbt-arrow-left",
                prevEl: ".rbt-arrow-right",
              }}
              breakpoints={{
                575: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 3,
                },
              }}
            >
              {data?.data?.map((item, index) => (
               
                  <SwiperSlide className="swiper-wrapper" key={index}>
                     <Link  href={`/instructor-profile/${item?.slug}`}>
                    <div className="rbt-team team-style-default style-two rbt-hover">
                      <div className="inner">
                        <div className="thumbnail">
                          <Image
                            src={`${process.env.NEXT_PUBLIC_FILE_BASE}${item?.User?.profileImageUrl}`}
                            width={415}
                            height={555}
                            priority
                            alt="Corporate Template"
                          />
                        </div>
                        <div className="content">
                          <h2 className="title">{item?.User?.name}</h2>
                          <h6 className="subtitle theme-gradient">
                            Instructor
                          </h6>
                          <span className="team-form">
                            <i className="feather-map-pin"></i>
                            <span className="location">{item?.User?.city}</span>
                          </span>
                          <p className="description" title={item?.User?.bio}>   {item?.User?.bio?.slice(0, 50)}
                          {item?.User?.bio?.length > 20 ? "..." : ""}</p>
                          <ul className="social-icon social-default icon-naked mt--20">
                            <li>
                              <Link href="https://www.facebook.com/edufixupofficial/">
                                <i className="feather-facebook"></i>
                              </Link>
                            </li>
                            <li>
                              <Link href="https://www.linkedin.com/company/edufixup/">
                                <i className="feather-twitter"></i>
                              </Link>
                            </li>
                            <li>
                              <Link href="https://m.youtube.com/channel/UCxapRqmreLAhvxy5IwadBxQ/">
                                <i className="feather-instagram"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    </Link>
                  </SwiperSlide>
            
              ))}

              <div className="rbt-swiper-arrow rbt-arrow-left">
                <div className="custom-overfolow">
                  <i className="rbt-icon feather-arrow-left"></i>
                  <i className="rbt-icon-top feather-arrow-left"></i>
                </div>
              </div>

              <div className="rbt-swiper-arrow rbt-arrow-right">
                <div className="custom-overfolow">
                  <i className="rbt-icon feather-arrow-right"></i>
                  <i className="rbt-icon-top feather-arrow-right"></i>
                </div>
              </div>

              <div
                className="rbt-swiper-pagination"
                style={{ bottom: "0" }}
              ></div>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamFive;
