/* eslint-disable no-sequences */

import {baseApi} from "../baseApi";

const eventApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createEvent: builder.mutation({
      query: (contactData) => ({
        url: "/events/new",
        method: "POST",
        data: contactData,
      }),
      invalidatesTags: ["events"],
    }),

    getAllPublicEvents: builder.query({
      query: () => ({
        url: "/events/public",
        method: "GET",
      }),
      providesTags: ["events"],
    }),
    updateEvent: builder.mutation({
      query: ({eventData, id}) => ({
        url: `/events/${id}`,
        method: "PUT",
        data: eventData,
      }),
      invalidatesTags: ["events"],
    }),
    deleteEvent: builder.mutation({
      query: (id) => ({
        url: `/events/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["events"],
    }),
  }),
});

export const {
  useCreateEventMutation,
  useGetAllPublicEventsQuery,
  useUpdateEventMutation,
  useDeleteEventMutation,
} = eventApi;
