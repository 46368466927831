import Image from "next/image";
import Link from "next/link";

import {Swiper, SwiperSlide} from "swiper/react";
import {EffectCards, Pagination} from "swiper/modules";

import {usePublicCourseGetQuery} from "@/redux/api/publicApi/course";
import {convertToBengaliNumerals} from "@/Hooks/convertBangaliDIgit";

const HomeCourses = ({start, end}) => {
  const {data} = usePublicCourseGetQuery();
  const getPriceAfterDiscount = (data) => {
    let discountAmount =
      (data?.CurrentCourseBatch?.CourseBatch?.discountPercentage / 100) *
      data?.CurrentCourseBatch?.CourseBatch?.price;

    return convertToBengaliNumerals(
      data?.CurrentCourseBatch?.CourseBatch?.price - discountAmount
    );
  };
  return (
    <>
      <Swiper
        className="swiper-wrapper"
        effect={"cards"}
        modules={[EffectCards, Pagination]}
        grabCursor={true}
        pagination={{
          el: ".rbt-swiper-pagination",
          clickable: true,
        }}>
        {data &&
          data?.data?.slice(start, end)?.map((data, index) => (
            <SwiperSlide className="swiper-slide" key={index}>
              <div className="rbt-card variation-01 rbt-hover">
                <div className="rbt-card-img">
                  <Link href={`/course-details/${data.slug}`}>
                    <Image
                      src={`${process.env.NEXT_PUBLIC_FILE_BASE}${data?.thumbnail}`}
                      width={710}
                      height={488}
                      alt="Card image"
                    />
                    {data?.CurrentCourseBatch?.CourseBatch?.discountPercentage >
                      0 && (
                      <div className="rbt-badge-3 bg-white">
                        <span>
                          {
                            data?.CurrentCourseBatch?.CourseBatch
                              ?.discountPercentage
                          }
                          %
                        </span>
                        <span>ছাড়</span>
                      </div>
                    )}
                  </Link>
                </div>
                <div className="rbt-card-body">
                  <ul className="rbt-meta">
                    {/* <li>
                      <i className="feather-book"></i>
                      {data?.CurrentCourseBatch?.CourseBatch.CourseMilestone.flatMap(
                        (milestone) =>
                          milestone.CourseModule.flatMap((module) =>
                            module.CourseLession.map((lesson) => ({
                              title: lesson.title,
                            }))
                          )
                      )}
                      Lessons
                    </li> */}
                    <li>
                      <i className="feather-users"></i>
                      {convertToBengaliNumerals(
                        data?.CurrentCourseBatch?.CourseBatch
                          ?.availableSeatsCount
                      )}{" "}
                      সিট বাকি
                    </li>
                  </ul>
                  <h4 className="rbt-card-title">
                    <Link href={`/course-details/${data?.slug}`}>
                      {data?.title}
                    </Link>
                  </h4>
                  {/* <p className="rbt-card-text">{data.desc.substring(0, 100)}</p> */}
                  {/* <div className="rbt-review">
                    <div className="rating">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </div>
                    <span className="rating-count">(50 Reviews)</span>
                  </div> */}
                  <div className="rbt-card-bottom">
                    <div className="rbt-price">
                      <span className="current-price">
                        {data?.type === "Free"
                          ? "ফ্রি"
                          : `৳ ${getPriceAfterDiscount(data)}`}
                      </span>
                      {data?.CurrentCourseBatch?.CourseBatch
                        ?.discountPercentage > 0 && (
                        <span className="off-price">
                          ৳{" "}
                          {convertToBengaliNumerals(
                            data?.CurrentCourseBatch?.CourseBatch?.price
                          )}
                        </span>
                      )}
                    </div>
                    <Link
                      className="rbt-btn-link"
                      href={`/course-details/${data.slug}`}>
                      <span className="btn-text">কোসটি কিনুন</span>
                      <span className="btn-icon">
                        <i className="feather-arrow-right"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        <div className="rbt-swiper-pagination"></div>
      </Swiper>
    </>
  );
};

export default HomeCourses;
